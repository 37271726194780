import React from "react";
import './ordermodal.component.scss';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import OrderModalForm from "./OrderModalForm";
import {useStoreon} from "storeon/react";
import Logotype from "../Logotype";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const OrderModal = () => {

    const {dispatch, orderModalOpened} = useStoreon('orderModalOpened');
    const {t} = useTranslation();

    const handleHideModal = () => {
        dispatch('orderModal/close');
    }

    return (
        <Modal
            className={'order-modal'}
            size={'lg'}
            show={orderModalOpened}
            onHide={handleHideModal}
            aria-labelledby={'order-modal-header'}
            centered
        >
            <Modal.Body>
                <button aria-label={t('Components.OrderForm.Close')} onClick={handleHideModal} className="order-modal__close">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {/* i don't want to use a Bootstrap modal header, it is damn ugly */}
                <div className="order-modal__header d-flex justify-content-center">
                    <Logotype />
                </div>
                <OrderModalForm onHide={handleHideModal}/>
            </Modal.Body>
        </Modal>
    )
}

export default OrderModal