import React from "react";

const FieldSet = ({
    label,
    className,
    children
}) => {
    return (
        <fieldset className={`${className ? `${className} ` : ''}mb-3 position-relative`}>
            {label &&
                <label className={'form-label label-required'}>{label}</label>
            }
            {children}
        </fieldset>
    );
};

export default FieldSet;