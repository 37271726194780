import React, {useEffect, useRef} from "react";
import './form.component.scss';
import {FetcherLoader, FormLoader} from '../Loaders'

const DefaultForm = ({onSubmit, isSubmitting, className, autoComplete, children, clearOnSubmit = false, relative = true}) => {

    const formRef = useRef(null)
    useEffect(() => {
        if (formRef.current) {
            formRef.current.querySelectorAll('input').forEach(item => {
                if (isSubmitting) {
                    item.setAttribute('disabled', true)
                } else {
                    item.removeAttribute('disabled')
                }
            })
        }
    }, [isSubmitting])

    return <>
        <form ref={formRef} className={`form ${relative && 'position-relative'} ${className} ${isSubmitting && 'form--submitting'}`} onSubmit={onSubmit} autoComplete={autoComplete}>
            {isSubmitting &&
                <FetcherLoader />
            }
            {!(isSubmitting && clearOnSubmit) &&
                children
            }
        </form>
    </>
};

export default DefaultForm;