import React from "react";
import {returnErrorClass} from "../../utils/forms";
import './input.component.scss';
import InputError from "./InputError";

const Checkbox = ({
        value,
        name,
        label,
        error,
        touched,
        onChange
}) => {
        return (
            <>
                <input value={value} type="checkbox" name={name} onChange={onChange}/>
                <div className={`state p-primary-o label-checkbox account-form__user-agreement-checkbox d-flex input-checkbox ${returnErrorClass(error, touched)} position-relative`}>
                        <label />
                        <div className={'account-form__user-agreement-checkbox__label px-2'}>
                                {label}
                        </div>
                        {error && touched &&
                            <InputError error={error} />
                        }
                </div>
            </>
        );
};

export default Checkbox;