import React from "react";
import {returnErrorClass} from "../../utils/forms";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputError from "./InputError";

const InputSelect = ({
                         error,
                         touched,
                         faIcon,
                         options = [],
                         name,
                         placeholder,
                         id,
                         value,
                         onChange
                     }) => {
    return (
        <div
            className={`position-relative input-wrapper w-100 h-100 d-flex justify-content-center align-items-center ${returnErrorClass(error, touched)}`}>
            {faIcon &&
            <div className="input-icon fa-icon-wrapper">
                <FontAwesomeIcon icon={faIcon} className={'fa-icon'}/>
            </div>
            }
            <select
                id={id}
                className={`form-control input-select`}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            >
                <option selected disabled value={null}>{placeholder}</option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
            {error && touched &&
                <InputError error={error} />
            }
        </div>
    )
}

export default InputSelect