import React from 'react';
import './ordermodal.component.scss';
import {useTranslation} from "react-i18next";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {POLL_URL} from "../../constants/Website";

const OrderModalSuccess = ({onHide = () => {}}) => {
    const {t} = useTranslation();
    return (
        <div className="order-modal__success-message text-center">
            <div className="order-modal__success-message__icon">
                <FontAwesomeIcon icon={faCheck} />
            </div>
            <div className="order-modal__success-message__block">
                <big>{t('Components.OrderForm.SuccessHeader')}</big>
            </div>
            <div className="order-modal__success-message__block mb-4">
                <span>{t('Components.OrderForm.SuccessMessage')}</span>
            </div>
            <hr />
            <div className="order-modal__success-message__block mt-4">
                <span>{t('Components.OrderForm.PollSuggestion')} <a href={POLL_URL} target={'_blank'} rel={'noreferrer'}>{t('Components.OrderForm.PollLink')}</a>.<br/>{t('Components.OrderForm.PollSuggestion2')}</span>
            </div>

            <div className={'mt-4 d-flex justify-content-center'}>
                <button onClick={onHide} className={'btn btn-primary btn-big button button-accent'}>
                    {t('Components.OrderForm.Close')}
                </button>
            </div>
        </div>
    )
}

export default OrderModalSuccess