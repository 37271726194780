const SITE_KEY = '6Ldbj34eAAAAAAxwFFd5kbRtE0GEd7K_E6-4adB2';

export const proceedCaptcha = callback => {
    if (typeof grecaptcha === 'undefined') {
        return console.error('Bug : grecaptcha is undefined');
    }
    // eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.execute(SITE_KEY, {action: 'submit'})
            .then(token => callback(token));
    })
};