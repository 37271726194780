import React from "react";
import {BarLoader} from "react-spinners";
import './loaders.component.scss';
import {IS_BROWSER} from "../../constants/Browser";

export const FetcherLoader = () => {
    return (
        <div className="loaders fetcher-loader w-100 h-100 position-absolute">
            <div className="m-5 p-5">
                {IS_BROWSER && <BarLoader color={'#ff0000'}  margin={4} />}
            </div>
        </div>
    )
}

export const FormLoader = () => {
    return (
        <div className="loaders form-loader">
            form loader...
        </div>
    )
}