import React, {useState} from "react";
import './input.component.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputError from "./InputError";
import {returnErrorClass} from "../../utils/forms";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const InputText = ({
    type = 'text',
    id,
    name,
    value,
    onChange,
    onInput,
    placeholder,
    faIcon,
    autoFocus = false,
    autocomplete,
    error,
    touched,
    className,
    disabled
}) => {

    // for password
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className={`position-relative input-wrapper w-100 h-100 d-flex justify-content-center align-items-center ${returnErrorClass(error, touched)}`}>
            {faIcon &&
            <div className="input-icon fa-icon-wrapper">
                <FontAwesomeIcon icon={faIcon} className={'fa-icon'} />
            </div>
            }
            <input
                type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                id={id}
                className={`${className ? `${className} ` : ''}form-control input-text input-${type} w-100 ${disabled ? 'disabled' : ''}`}
                name={name}
                placeholder={placeholder}
                autoComplete={autocomplete}
                onChange={onChange}
                onInput={onInput}
                value={value}
                autoFocus={autoFocus}
                disabled={disabled}
            />
            {type === 'password' &&
                <div className="input-button fa-icon-wrapper user-select-none" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword
                        ? <FontAwesomeIcon icon={faEyeSlash} className={'fa-icon'} />
                        : <FontAwesomeIcon icon={faEye} className={'fa-icon'} />
                    }
                </div>
            }
            {error && touched &&
                <InputError error={error} />
            }
        </div>
    );
};

export default InputText;