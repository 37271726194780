import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

export const returnErrorClass = (error, touched) => {
    return error && touched ? 'form-error' : '';
};

export const WithTranslateFormErrors = ({errors, children}) => {
    const {t} = useTranslation();
    const [errorsTranslated, setErrorsTranslated] = useState(errors);

    const translateValue = value => {
        if (!value) return value;
        if (typeof value === 'object') {
            return translateObject(value);
        } else if (typeof value === 'string') {
            return t(value);
        }
    }

    const translateObject = object => {
        const errorsToSet = {};
        Object.keys(object).forEach(error => {
            errorsToSet[error] = translateValue(object[error]);
        });
        return errorsToSet;
    };

    useEffect(() => {
        setErrorsTranslated(translateObject(errors));
    }, [errors]);
    return (
        typeof children === 'function'
            ? children({errors: errorsTranslated})
            : children
    );
};

// todo refactor
export const proceedErrors = (response, setError, t, fallbackAlert = () => {}) => {
    if (response && (response.status === 400 || response.status === 401) && response.data && Object.keys(response.data).length > 0) {
        const errorsObject = response.data;

        // replace detail with password
        if (errorsObject['detail']) {
            errorsObject['password'] = errorsObject['detail']
            // delete errorsObject['detail']
        }

        for (let field in errorsObject) {
            if (!errorsObject.hasOwnProperty(field)) {
                continue;
            }

            setError(field, Array.isArray(errorsObject[field]) ? errorsObject[field][0] : errorsObject[field]);
        }
    } else {
        if (fallbackAlert && typeof fallbackAlert === 'function') {
            fallbackAlert();
        }
    }
};