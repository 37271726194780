import React, {useState} from "react";
import {Formik} from "formik";
import {OrderFormSchema} from "../../schemas";
import {proceedErrors, returnErrorClass, WithTranslateFormErrors} from "../../utils/forms";
import {DefaultForm} from './../Form';
import {Checkbox, FieldSet, InputText} from "../Input";
import {faUser, faEnvelope, faQuoteRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import InputSelect from "../Input/InputSelect";
import InputError from "../Input/InputError";
import {useStoreon} from "storeon/react";
import {proceedCaptcha} from "../../utils/captcha";
import api from "../../api";
import {useAlert} from "react-alert";
import OrderModalSuccess from "./OrderModalSuccess";

const OrderModalForm = ({onHide = () => {}}) => {

    const {t} = useTranslation();
    const {orderForm, dispatch} = useStoreon('orderForm');
    const alert = useAlert();

    const [isSuccess, setIsSuccess] = useState(false);

    const selectTypeChoices = [
        {
            value: 'order',
            label: t('Components.OrderForm.Choices.Order')
        },
        {
            value: 'partnership',
            label: t('Components.OrderForm.Choices.Partnership')
        },
        {
            value: 'other',
            label: t('Components.OrderForm.Choices.Other')
        },
    ]

    const handleSubmit = (params, {setSubmitting, setFieldError}) => {
        proceedCaptcha(token => {
           api.submitOrder({...params, token})
               .then(() => {
                   setIsSuccess(true);
                   dispatch('orderForm/replace', {});
                   setSubmitting(false);
               })
               .catch(error => {
                   proceedErrors(error.response, setFieldError, t, () => {
                       alert.error(t('Components.OrderForm.Error'));
                   });

                   console.error(error);
                   setSubmitting(false);
               })
        });
    }

    if (isSuccess) {
        return <OrderModalSuccess onHide={onHide}/>
    } else {
        return (
            <div className="order-modal__form">
                <Formik
                    validationSchema={OrderFormSchema}
                    enableReinitialize={false}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    isInitialValid={false}
                    initialValues={{
                        'name': orderForm.name ?? '',
                        'email': orderForm.email ?? '',
                        'type': orderForm.type,
                        'description': orderForm.description ?? '',
                        'agreement': orderForm.agreement ?? false
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange: handleChangeReal,
                          values,
                          errors,
                          touched,
                          isSubmitting,
                          isValid
                      }) => (
                        <WithTranslateFormErrors errors={errors}>
                            {({errors}) => {

                                const handleChange = params => {
                                    dispatch('orderForm/replace', {...orderForm, [params.target.name]: params.target.value});
                                    handleChangeReal(params);
                                }

                                return <>
                                    <DefaultForm onSubmit={handleSubmit} isSubmitting={isSubmitting} relative={false}>
                                        <FieldSet>
                                            <InputText
                                                faIcon={faUser}
                                                type="text"
                                                name="name"
                                                placeholder={t('Components.OrderForm.Name')}
                                                value={values.name}
                                                autoFocus={true}
                                                error={errors.name}
                                                touched={touched.name}
                                                autocomplete={'off'}
                                                onChange={handleChange}
                                            />
                                        </FieldSet>
                                        <FieldSet>
                                            <InputText
                                                faIcon={faEnvelope}
                                                type="text"
                                                name="email"
                                                placeholder={t('Components.OrderForm.Email')}
                                                value={values.email}
                                                error={errors.email}
                                                touched={touched.email}
                                                autocomplete={'off'}
                                                onChange={handleChange}
                                            />
                                        </FieldSet>
                                        <FieldSet>
                                            <InputSelect
                                                defaultValue={null}
                                                faIcon={faQuoteRight}
                                                name="type"
                                                placeholder={t('Components.OrderForm.Type')}
                                                value={values.type}
                                                error={errors.type}
                                                touched={touched.type}
                                                onChange={handleChange}
                                                options={selectTypeChoices}
                                            />
                                        </FieldSet>
                                        <FieldSet>
                                            <div
                                                className={`input-wrapper ${returnErrorClass(errors.description, touched.description)}`}>
                                        <textarea
                                            defaultValue={values.description}
                                            // value={values.bio}
                                            autoComplete={'off'}
                                            onChange={handleChange}
                                            name={'description'}
                                            className={'order-modal__form__textarea form-control'}
                                            placeholder={t('Components.OrderForm.Description')}
                                        />
                                            </div>
                                            {errors.description && touched.description &&
                                            <InputError error={errors.description}/>
                                            }
                                        </FieldSet>
                                        <div className="d-flex justify-content-between w-100 mt-4">
                                            <FieldSet className={`pretty p-default p-curve p-thick checkbox-primary w-100`}>
                                                <Checkbox
                                                    value={values.agreement}
                                                    name={'agreement'}
                                                    onChange={handleChange}
                                                    label={<>{t('Components.OrderForm.Agreement')}</>}
                                                    error={errors.agreement}
                                                    touched={touched.agreement}
                                                />
                                            </FieldSet>
                                        </div>
                                        <div className={'mt-4 d-flex justify-content-center'}>
                                            <button disabled={!isValid} type={'submit'} className={'btn btn-primary btn-big button button-accent'}>
                                                {t('Components.OrderForm.Button')}
                                            </button>
                                        </div>
                                    </DefaultForm>
                                </>
                            }}
                        </WithTranslateFormErrors>
                    )}
                </Formik>
            </div>
        )
    }
}

export default OrderModalForm;